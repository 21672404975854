<template>
	<!-- start 底部栏 -->
	<div class="bottom-bar">
		<div class="bottom-information">
			<div class="bottom-information-right">
				<div class="navigation-box">
					<div class="navigation-top">
						<div class="navigation-text" @click="toggleNavigation('/')">关于有容</div>
						<div class="navigation-text" @click="toggleNavigation(pathUrl[index].url)"
							v-for="(item,index) in categoryList.slice(0,4)">{{item.categoryName}}</div>
						<div class="navigation-text" @click="toggleNavigation('/professional-field')">专业领域</div>
						<div class="navigation-text" @click="toggleNavigation('/news-center')">法律知识</div>
						<div class="navigation-text" @click="toggleNavigation('/contact-us')">联系我们</div>
						<div class="navigation-text" @click="toggleNavigation('/sitemap')">网站地图</div>
					</div>
					<div class="navigation-bottom">
						<div class="service-hotline-box">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-phone.png" />
							<div class="service-hotline-content">
								<div class="service-hotline-title">服务热线</div>
								<div class="service-hotline-number">{{enterpriseInfo.phone}}</div>
							</div>
						</div>
						<div class="service-hotline-box">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-weChat.png" />
							<div class="service-hotline-content">
								<div class="service-hotline-title">微信号</div>
								<div class="service-hotline-number">{{enterpriseInfo.phone}}</div>
							</div>
						</div>
						<div class="service-hotline-box">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-email.png" />
							<div class="service-hotline-content">
								<div class="service-hotline-title">邮箱</div>
								<div class="service-hotline-number">{{enterpriseInfo.email}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="bottom-information-left">
				<div class="applets-box">
					<div class="applets-text">有容二维码</div>
					<div class="weChatCodeUrl">
						<img :src="enterpriseInfo.weChatCodeUrl" />
					</div>
				</div>
			</div>
		</div>
		<div class="address-filing">
			<div class="address-filing-text">{{enterpriseInfo.address}}</div>
			<div class="address-filing-text">©2022 有容律师事务所 备案号：<a href="https://beian.miit.gov.cn"
					target="_blank">鲁ICP备2022001904号</a></div>
			<div class="address-filing-text"><img class="gongan" src="../../../static/image/footer/gongan.png" /><a
					href="http://www.beian.gov.cn" target="_blank">京公网安备 **************</a></div>
		</div>
	</div>
	<!-- end 底部栏 -->
</template>

<script>
	import {
		getContactUsList,
		getCategoryList
	} from '../../apis/openapi.js';
	export default {
		data() {
			return {
				enterpriseInfo: {}, //企业信息
				categoryList: [], //分类信息
				pathUrl: [{
						url: '/accounts-receivable'
					},
					{
						url: '/legal-counsel'
					},
					{
						url: '/criminal'
					},
					{
						url: '/civil'
					},
				]

			}
		},
		components: {},
		created() {},
		mounted() {
			if (this.$store.state.categoryInfo) {
				this.categoryList = this.$store.state.categoryInfo
			} else {
				this.getCategoryList()
			}
			if (this.$store.state.companyInfo) {
				this.enterpriseInfo = this.$store.state.companyInfo
			} else {
				this.getContactUsList()
			}
		},
		methods: {
			//查询分类
			getCategoryList() {
				let params = {
					page: 1,
					rows: 9999,
					categoryName: '',
					parentUuid: '0',
					categoryState: ''
				}
				getCategoryList(params).then(res => {
					if (res.data.success == 'true') {
						this.categoryList = res.data.data.data
						var categoryInfo = res.data.data.data
						this.$store.commit('modifyCategoryInfo', categoryInfo);
						localStorage.setItem('categoryInfo', JSON.stringify(categoryInfo))
					}
				})
			},
			//查询企业信息
			getContactUsList() {
				getContactUsList({}).then(res => {
					if (res.data.success == 'true') {
						this.enterpriseInfo = res.data.data
					}
				});
			},
			//跳转到民商事
			toCivil(civilCategoryIndex, secCategoryUuid) {
				this.$router.push('/civil/' + civilCategoryIndex + '/' + secCategoryUuid)
			},
			//跳转分类（路由）
			toggleNavigation(url) {
				if (url == '/civil') {
					this.toCivil(-1, '0')
					return;
				}
				this.$router.push({
					path: url,
					query: {}
				});
			},
		}
	}
</script>


<style lang="scss" scoped>
	.bottom-bar {
		width: 100%;
		height: 200px;
		background: #2A2C37;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: absolute;
		z-index: 10;
		opacity: 1;

		.bottom-information {
			//width: 1200px;
			height: 144px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			padding: 0 52px;
			box-sizing: border-box;

			.bottom-information-right {

				.navigation-box {
					margin-top: 30px;

					.navigation-top {
						display: flex;
						align-items: center;

						.navigation-text {
							height: 14px;
							font-size: 14px;
							font-family: SourceHanSansCN-Regular;
							font-weight: 400;
							line-height: 14px;
							color: #999999;
							letter-spacing: 0.28px;
							opacity: 1;
							margin-right: 35px;
							cursor: pointer;
						}

						.navigation-text:hover {
							color: #FFFFFF;
						}
					}

					.navigation-bottom {
						display: flex;
						align-items: center;
						margin-top: 30px;
						margin-left: 24px;

						.service-hotline-box {
							display: flex;
							margin-right: 100px;

							.service-hotline-img {
								width: 40px;
								height: 40px;
							}

							.service-hotline-content {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								margin-left: 14px;

								.service-hotline-title {
									height: 14px;
									font-size: 14px;
									font-family: SourceHanSansCN-Regular;
									font-weight: 400;
									line-height: 14px;
									color: #999999;
									letter-spacing: 0.28px;
									opacity: 1;
								}

								.service-hotline-number {
									height: 14px;
									font-size: 14px;
									font-family: SourceHanSansCN-Regular;
									font-weight: 400;
									line-height: 14px;
									color: #999999;
									letter-spacing: 0.28px;
									opacity: 1;
								}
							}
						}
					}
				}
			}

			.bottom-information-left {
				margin-top: 21px;
				margin-left: 56px;

				.applets-box {
					display: flex;
					align-items: center;

					.applets-text {
						height: 14px;
						font-size: 14px;
						font-family: SourceHanSansCN-Regular;
						font-weight: 400;
						line-height: 14px;
						color: #999999;
						letter-spacing: 0.28px;
						opacity: 1;
						margin-right: 16px;
					}

					.weChatCodeUrl {
						width: 102px;
						height: 102px;
						background-color: #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;

						img {
							width: 90px;
							height: 90px;
						}
					}
				}
			}

		}

		.address-filing {
			width: 100%;
			height: 56px;
			background: #171C28;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;

			.address-filing-text {
				height: 14px;
				font-size: 14px;
				font-family: SourceHanSansCN-Regular;
				font-weight: 400;
				line-height: 14px;
				color: #999999;
				letter-spacing: 0.28px;
				opacity: 1;
				margin-right: 60px;
				display: flex;
				align-items: center;

				.gongan {
					margin-right: 5px;
				}

				a {
					height: 14px;
					font-size: 14px;
					font-family: SourceHanSansCN-Regular;
					font-weight: 400;
					line-height: 14px;
					color: #999999;
					letter-spacing: 1px;
				}

				a {
					text-decoration: none;
				}
			}
		}

	}
</style>
