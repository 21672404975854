<template>
	<div id="app">
		<div v-if="WhetherPC">
			<!-- start pc顶部菜单 -->
			<headermenu :isScroll="isScroll" v-if="WhetherPC && !routerNameList.includes($route.name)"></headermenu>
			<!-- end 顶部菜单 -->
			<div style="min-height: calc(100vh - 200px);">
				<router-view />
			</div>
			<!-- start pc底部栏 -->
			<footerpage v-if="WhetherPC"></footerpage>
			<!-- end 底部栏 -->
			<div @click="backToTop('#app')" class="toTop" v-if="$route.path != '/' && scroll>400">
				<div class="toTop-image"></div>
				<div class="toTop-text">返回顶部</div>
			</div>
		</div>
		<div v-if="!WhetherPC">
			<!-- @click="$refs.headermenuApp.showNavigation = false" -->
			<!-- start app顶部菜单 -->
			<headermenuApp ref="headermenuApp" v-if="!WhetherPC"></headermenuApp>
			<!-- end app顶部菜单 -->
			<div style="min-height: 100vh;">
				<router-view />
			</div>
			<!-- start app底部栏 -->
			<footerpageApp v-if="!WhetherPC"></footerpageApp>
			<!-- end app底部栏 -->
		</div>
	</div>
</template>
<script>
	import {
		getContactUsList,
		getCategoryList,
		getProfessionalFieldList
	} from '../src/apis/openapi.js';
	import headermenuApp from '@/components/header/header-app.vue'
	import headermenu from '@/components/header/header.vue'
	import footerpage from '@/components/footer/footer.vue'
	import footerpageApp from '@/components/footer/footer-app.vue'
	export default {
		components: {
			headermenu,
			footerpage,
			footerpageApp,
			headermenuApp
		},
		data() {
			return {
				//不显示头部路由
				routerNameList:['home'],
				scroll: 0,//滑动距离顶部的高度
				isScroll:false,//是否有下滑栏
				WhetherPC: true,
				enterpriseInfo: {}, //企业信息
				categoryList: [], //分类信息
				fieldList: [],//专业领域分类信息
			}
		},
		watch: {
		  $route: {
		    handler: function(val, oldVal){
				this.$nextTick(()=>{
					if (document.documentElement.clientHeight < document.documentElement.offsetHeight){
						 this.isScroll = true
					}else{
						 this.isScroll = false
					}
				})
			},
		    // 深度观察监听
		    deep: true
		  }
		},
		created() {},
		destroyed() {
			window.removeEventListener('scroll', this.dataScroll, false)
		},
		mounted() {
			//百度统计
			this.baiduStatistics()
			window.addEventListener('scroll', this.dataScroll, false)
			this.getContactUsList()
			this.getCategoryList()
			this.getProfessionalFieldList()
			if (this._isMobile()) {
				this.WhetherPC = false
				//alert("手机端");
				//this.$router.replace('/homeApp');
			} else {
				this.WhetherPC = true
				//alert("pc端");
				// this.$router.replace('/');
			}
		},

		methods: {
			dataScroll: function() {
				this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
				this.$store.state.scroll = this.scroll
			},
			//返回顶部
			goAuchor(id) {
				document.querySelector(id).scrollIntoView({
					behavior: "smooth"
				});
			},
			//返回顶部
			backToTop(){
				this.scrollToptimer = setInterval(() => {
					var top = document.body.scrollTop || document.documentElement.scrollTop;
					this.backScrollTop = top
					var speed = top / 4;
					if (document.body.scrollTop != 0) {
						document.body.scrollTop -= speed;
					} else {
						document.documentElement.scrollTop -= speed;
					}
					if (top == 0) {
						clearInterval(this.scrollToptimer);
					}
				}, 30);
			},
			//查询专业领域信息
			getProfessionalFieldList() {
				let headFieldList = []
				let params = {
					page:1,
					rows:100
				}
				getProfessionalFieldList(params).then(res => {
					if (res.data.success == 'true') {
						this.fieldList = res.data.data.data
						this.fieldList.forEach((item,key)=>{
							headFieldList.push({
								fieldName:item.fieldName,
								sort:item.sort,
								uuid:item.uuid,
							})
						})
						this.$store.commit('modifyHeadFieldList', headFieldList);
						localStorage.setItem('headFieldList', JSON.stringify(headFieldList))
					}
				});
			},
			//查询企业信息
			getContactUsList() {
				getContactUsList({}).then(res => {
					if (res.data.success == 'true') {
						this.enterpriseInfo = res.data.data
						var companyInfo = res.data.data
						this.$store.commit('modifyCompanyInfo', companyInfo);
						localStorage.setItem('companyInfo', JSON.stringify(companyInfo))
					}
				});
			},
			//查询分类
			getCategoryList() {
				let params = {
					page: 1,
					rows: 9999,
					categoryName: '',
					parentUuid: '0',
					categoryState: ''
				}
				getCategoryList(params).then(res => {
					if (res.data.success == 'true') {
						this.categoryList = res.data.data.data
						var categoryInfo = res.data.data.data
						this.$store.commit('modifyCategoryInfo', categoryInfo);
						localStorage.setItem('categoryInfo', JSON.stringify(categoryInfo))
					}
				})
			},
			//vue 判断设备是手机端还是pc端
			_isMobile() {
				let flag = navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
				return flag;
			},
			/* //百度统计*/
			baiduStatistics() {
				var _hmt = _hmt || [];
				(function() {
					var hm = document.createElement("script");
					hm.src = "https://hm.baidu.com/hm.js?51ffba7bba974473c8d69f247cce19f6";
					var s = document.getElementsByTagName("script")[0];
					s.parentNode.insertBefore(hm, s);
				})();
			},

		}
	}
</script>



<style lang="scss">
	/* 字体  SourceHanSansCN-Regular  SourceHanSansCN-Medium  SourceHanSansCN-Normal  */
	@import url('assets/fonts/font.css');
	/* 字体  PingFangSC-Bold  PingFangSC-Medium PingFangSC-Regular PingFangSC-Semibold*/
	@import url('assets/font-app/font.css');
	@import url('../static/js/overhang/overhang.min.css');

	* {
		margin: 0px;
		padding: 0px;
	}

	html {
		font-size: none;
	}

	img {
		image-rendering: -moz-crisp-edges;
		/* Firefox */
		image-rendering: -o-crisp-edges;
		/* Opera */
		image-rendering: -webkit-optimize-contrast;
		/*Webkit (non-standard naming) */
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
		/* IE (non-standard property) */
	}
	/* 清除滚动条 */
	 ::-webkit-scrollbar {
	  display: none;
	 }
	.toTop {
		width: 50px;
		//height: 40px;
		background: #F5F8FA;
		opacity: 1;
		border-radius: 4px;
		position: fixed;
		right: 1%;
		bottom: 20%;
		z-index: 9999;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 6px 6px 8px 6px;
		box-sizing: border-box;

		.toTop-image {
			width: 16px;
			height: 8px;
			background-image: url(../static/image/home/to-top.png);
			background-size: cover;
			background-repeat: no-repeat;
		}
		.toTop-text{
			width: 28px;
			height: 33px;
			font-size: 13px;
			font-family: SourceHanSansCN-Regular;
			font-weight: 400;
			line-height: 19px;
			color: #999999;
			letter-spacing: 0.26px;
			opacity: 1;
			margin-top: 6px;
		}
	}

	.toTop:hover {
		transition: all 0.4s;
		background: #AB0101;

		.toTop-image {
			transition: all 0.4s;
			background-image: url(../static/image/home/to-top-hover.png);
		}
		.toTop-text{
			color: #FFFFFF;
		}
	}
</style>
