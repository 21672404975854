import md5Utils from './md5Encrypt.js'
import CryptoJS from './tripledes.js'
import store from '../../src/store/index.js'
import CryptoJSAES from './aes.js'
var util = {}
util.tripledesPass = function(param){
	var key = CryptoJSAES.enc.Base64.parse(store.state.cryptoJSKey);
	var iv = CryptoJSAES.enc.Base64.parse(store.state.cryptoJSIv);
	var cipherText = CryptoJSAES.AES.encrypt(JSON.stringify(param), key, {iv: iv,padding: CryptoJSAES.pad.Pkcs7,mode: CryptoJSAES.mode.CBC}).ciphertext.toString();
	return cipherText
}
export default util