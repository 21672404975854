<template>
	<!-- start 顶部菜单 -->
	<div class="header-box" @mouseleave="handleMouseLeave()">
		<div class="navigation-box">
			<img class="logo-image" @click="toggleNavigation('/')" src="../../../static/image/home/logo.png" />
			<div class="classification-box">
				<div class="classification-text-box">
					<div class="classification-text" @click="toggleNavigation('/')"
						:style="{color:$route.path == '/'?'#AB0101':fontColor}">关于有容</div>
					<div class="classification-line" :class="$route.path == '/' ?'classification-text-hover':''"></div>	
				</div>
				<div @mouseleave="handleMouseLeave()" class="classification-text-box" v-for="(item,index) in categoryList.slice(0,4)">
					<div class=" classification-text" @click="toggleNavigation(pathUrl[index].url)"  @mouseenter="handleMouseEnter('civil',item)"
						:style="{color:$route.name == pathUrl[index].name || selectCivil&&item.categoryName == '民商事'?'#AB0101':fontColor}">
						{{item.categoryName}}
					</div>
					<div class="classification-line" :class="$route.name == pathUrl[index].name ?'classification-text-hover':''"></div>
					<div v-if="item.categoryName == '民商事'">
						<div class="classification-image-hover" v-if="selectCivil"></div>
						<div class="classification-image-select" v-else-if="$route.name == 'civil'"></div>
						<div class="classification-image" v-else></div>
						<div class="classification-category-select-box" v-if="selectCivil" >
							<div style="height: 22px;"></div>
							<div class="classification-category" style="width: 80px;left: -8px;" >
								<div class="classification-category-box" v-for="(itemCate,indexCate) in civilCategoryList.slice(0,5)">
									<div @click="toCivil(indexCate,itemCate.uuid)"
										:style="[{marginTop:indexCate == 0 ?'0px':'10px'},{color:$route.params.civilCategoryIndex == indexCate ?'#AB0101':'#666666'}]"
										class="classification-category-text">{{itemCate.categoryName}}</div>
								</div>
						</div>
						</div>
					</div>
				</div>
				<div class="classification-text-box" @mouseleave="handleMouseLeave()">
					<div class="classification-text" @click="toggleNavigation('/professional-field')" @mouseenter="handleMouseEnter('profes')"
						:style="{color:$route.path == '/professional-field' ||$route.name == 'professionalFieldDetails' || selectProfes?'#AB0101':fontColor}">
						专业领域</div>
					<div class="classification-line" :class="$route.path == '/professional-field' ||$route.name == 'professionalFieldDetails' ?'classification-text-hover':''"></div>
					<div class="classification-image-hover" v-if="selectProfes"></div>
					<div class="classification-image-select" v-else-if="$route.path == '/professional-field' ||$route.name == 'professionalFieldDetails'"></div>
					<div class="classification-image" v-else></div>
					<div class="classification-category-select-box" v-if="selectProfes" >
						<div style="height: 22px;"></div>
						<div class="classification-category">
							<div class="classification-category-box" v-for="(item,index) in fieldList">
								<div @click="toProfessional(item.uuid)"
									:style="[{marginTop:index == 0 ?'0px':'10px'},{color:$route.params.uuid == item.uuid ?'#AB0101':'#666666'}]"
									class="classification-category-text">{{item.fieldName}}</div>
							</div>
						</div>
					</div>
					
				</div>
				<div class="classification-text-box">
					<div class=" classification-text" @click="toggleNavigation('/news-center')"
						:style="{color:$route.path == '/news-center' || $route.name == 'newsDetails'?'#AB0101':fontColor}">法律知识
					</div>
					<div class="classification-line" :class="$route.path == '/news-center' || $route.name == 'newsDetails' ?'classification-text-hover':''"></div>
				</div>
				<div class="classification-text-box">
					<div class="classification-text" @click="toggleNavigation('/contact-us')"
						:style="{color:$route.path == '/contact-us'?'#AB0101':fontColor}">联系我们
					</div>
					<div class="classification-line" :class="$route.path == '/contact-us' ?'classification-text-hover':''"></div>
				</div>
			</div>
		</div>
		<!-- <div style="height: 110px;width: 17px;" v-if="!isScroll"></div> -->
	</div>
	<!-- end 顶部菜单 -->
</template>

<script>
	import {
		getContactUsList,
		getCategoryList,
		getProfessionalFieldList
	} from '../../apis/openapi.js';
	export default {
		props: {
			//是否有下滑栏
			isScroll:{
				type:Boolean,
				default:false
			}
		},
		data() {
			return {
				enterpriseInfo: {}, //企业信息
				categoryList: [{
						categoryName: '应收账款'
					},
					{
						categoryName: '法律顾问'
					},
					{
						categoryName: '刑事'
					},
					{
						categoryName: '民商事'
					},
				], //分类信息
				pathUrl: [{
						name: 'accountsReceivable',
						url:'/accounts-receivable'
					},
					{
						name: 'legalCounsel',
						url:'/legal-counsel'
					},
					{
						name: 'criminal',
						url:'/criminal'
					},
					{
						name: 'civil',
						url:'/civil'
					},
				],
				fieldList: [],
				civilCategoryList: [{
						categoryName: '合同纠纷'
					},
					{
						categoryName: '婚姻遗产'
					},
					{
						categoryName: '侵权纠纷'
					},
					{
						categoryName: '劳动纠纷'
					},
					{
						categoryName: '股权'
					},
				],
				selectProfes: false, //是否选中了专业领域
				selectCivil: false, //是否选中了民商事 
				fontColor:'#FFFFFF',//字体默认颜色
			}
		},
		components: {},
		created() {},
		watch: {
		  $route: {
		    handler: function(val, oldVal){
				this.$nextTick(()=>{
					this.initMenuStatus()
				})
			},
		    // 深度观察监听
		    deep: true
		  }
		},
		mounted() {
			this.$nextTick(()=>{
				this.initMenuStatus()
			})
			if (this.$store.state.categoryInfo) {
				this.categoryList = this.$store.state.categoryInfo
				this.civilCategoryList = this.categoryList.find((item)=>item.categoryName=='民商事').children
			} else {
				this.getCategoryList()
			}
			if (this.$store.state.companyInfo) {
				this.enterpriseInfo = this.$store.state.companyInfo
			} else {
				this.getContactUsList()
			}
			if (this.$store.state.headFieldList) {
				this.fieldList = this.$store.state.headFieldList
			} else {
				this.getProfessionalFieldList()
			}
		},
		methods: {
			initMenuStatus(){
				let meta = this.$route.meta
				let params = this.$route.params
				let headMenu = document.getElementsByClassName('header-box')[0]
				let logoImage = document.getElementsByClassName('logo-image')[0]
				if(meta.whiteDetail && params.uuid && params.uuid!='0'){
					headMenu.classList.add('white-header')
					this.fontColor = '#333333'
					logoImage.src = require('../../../static/image/home/logo-black.png')
				} else{
					headMenu.classList.remove('white-header')
					this.fontColor = '#FFFFFF'
					logoImage.src = require('../../../static/image/home/logo.png')
				}
			},
			handleMouseEnter(post, item) {
				if (post == 'profes') {
					this.selectProfes = true
					this.selectCivil = false
				} else if (post == 'civil') {
					if (item.categoryName == '民商事') {
						this.selectProfes = false
						this.selectCivil = true
					}
				}
			},
			handleMouseLeave() {
				this.selectCivil = false
				this.selectProfes = false
			},
			//查询专业领域信息
			getProfessionalFieldList() {
				let headFieldList = []
				let params = {
					page: 1,
					rows: 100
				}
				getProfessionalFieldList(params).then(res => {
					if (res.data.success == 'true') {
						this.fieldList = res.data.data.data
						this.fieldList.forEach((item, key) => {
							headFieldList.push({
								fieldName: item.fieldName,
								sort: item.sort,
								uuid: item.uuid,
							})
						})
						this.$store.commit('modifyHeadFieldList', headFieldList);
						localStorage.setItem('headFieldList', JSON.stringify(headFieldList))
					}
				});
			},
			//查询分类
			getCategoryList() {
				let params = {
					page: 1,
					rows: 9999,
					categoryName: '',
					parentUuid: '0',
					categoryState: ''
				}
				getCategoryList(params).then(res => {
					if (res.data.success == 'true') {
						this.categoryList = res.data.data.data
						var categoryInfo = res.data.data.data
						this.$store.commit('modifyCategoryInfo', categoryInfo);
						localStorage.setItem('categoryInfo', JSON.stringify(categoryInfo))
						this.civilCategoryList = this.categoryList.find((item)=>item.categoryName=='民商事').children
					}
				})
			},
			//查询企业信息
			getContactUsList() {
				getContactUsList({}).then(res => {
					if (res.data.success == 'true') {
						this.enterpriseInfo = res.data.data
					}
				});
			},
			//跳转到民商事 
			toCivil(civilCategoryIndex,secCategoryUuid) {
				this.$router.push('/civil/'+civilCategoryIndex+'/'+secCategoryUuid)
			},
			//跳转到专业邻域
			toProfessional(uuid) {
				this.$router.push('/professional-field-details/'+uuid)
			},
			//跳转分类（路由）
			toggleNavigation(url) {
				if(url == '/civil'){
					this.toCivil(-1,'0')
					return;
				}
				this.$router.push({
					path: url,
					query: {
					}
				});
			},

		}
	}
</script>

<style lang="scss" scoped>
	.white-header{
		background: #FFFFFF;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
		.classification-category{
			background: rgba(255, 255, 255, 1.0) !important;
			opacity: 1 !important;
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2) !important;
		}
		.classification-image{
			background-image: url(../../../static/image/home/classification-black.png) !important;
		}
	}
	.header-box {
		width: 100%;
		height: 80px;
		background: rgba(51, 51, 51, 0);
		display: flex;
		align-items: flex-end;
		position: absolute;
		top: 0;
		z-index: 10;

		.navigation-box {
			width: 1200px;
			margin: 0 auto;
			padding: 0 0 5px 50px;
			box-sizing: border-box;
			display: flex;
			align-items: center;

			.logo-image {
				width: 116px;
				height: 50px;
				opacity: 1;
				box-sizing: border-box;
				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 15px;

			}

			.classification-box {
				display: flex;
				align-items: center;
				margin-left: 120px;

				.classification-text-box {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					margin-right: 60px;
					position: relative;

					.classification-text {
						height: 15px;
						font-size: 15px;
						font-family: SourceHanSansCN-Medium;
						font-weight: 500;
						line-height: 15px;
						color: #FFFFFF;
						letter-spacing: 0.30px;
						opacity: 1;
						cursor: pointer;
						white-space: nowrap;
					}
					.classification-line{
						width: 0px;
						border-top: 2px solid #AB0101;
						opacity: 1;
						position: absolute;
						bottom: -18px;
						
					}
					.classification-text-hover {
						width: 30px !important;
						//animation: ad_width .2s linear forwards;
					}

					.classification-image {
						width: 8px;
						height: 4px;
						background-image: url(../../../static/image/home/classification.png);
						background-size: cover;
						background-repeat: no-repeat;
						//margin-left: 6px;
						position: absolute;
						top: 7px;
						right: -15px;
					}

					.classification-image-hover {
						width: 8px;
						height: 4px;
						background-image: url(../../../static/image/home/classification-hover.png);
						background-size: cover;
						background-repeat: no-repeat;
						//margin-left: 6px;
						position: absolute;
						top: 7px;
						right: -15px;
					}

					.classification-image-select {
						width: 8px;
						height: 4px;
						background-image: url(../../../static/image/home/classification-select.png);
						background-size: cover;
						background-repeat: no-repeat;
						//margin-left: 6px;
						position: absolute;
						top: 7px;
						right: -15px;
					}
					
					.classification-category-select-box{
						position: absolute;
						top: 15px;
						left: -5px;
						cursor: pointer;
					}
					.classification-category {
						width: 170px;
						// height: 290px;
						background: rgba(245, 248, 250, 1);
						border-radius: 4px;
						padding: 12px;
						box-sizing: border-box;

						.classification-category-box {
							cursor: pointer;

							.classification-category-text {
								//height: 13px;
								font-size: 13px;
								font-family: SourceHanSansCN-Regular;
								font-weight: 400;
								line-height: 13px;
								color: #666666;
								letter-spacing: 0.26px;
								opacity: 1;
								margin-top: 10px;
							}
						}
					}

					.classification-category-box:hover {
						.classification-category-text {
							color: #AB0101 !important;
						}
					}
				}

				.classification-text-box:hover {
					.classification-text {
						color: #AB0101 !important;
					}
					.classification-line {
						transition: all 0.4s;
						animation: ad_width .2s linear forwards;
					}


					.classification-image {
						background-image: url(../../../static/image/home/classification-hover.png);
					}
				}
				@keyframes ad_width {
					from {
						width: 0
					}
				
					to {
						width: 30px
					}
				}

				/* Underline From Center */
				.hvr-underline-from-center {
					display: inline-block;
					vertical-align: middle;
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					box-shadow: 0 0 1px rgba(0, 0, 0, 0);
					-webkit-backface-visibility: hidden;
					backface-visibility: hidden;
					-moz-osx-font-smoothing: grayscale;
					position: relative;
					// overflow: hidden;
				}

				.hvr-underline-from-center:before {
					content: "";
					position: absolute;
					z-index: -1;
					left: 50%;
					right: 50%;
					bottom: -18px;
					background: #AB0101;
					height: 2px;
					//border-radius: 4px;
					-webkit-transition-property: left, right;
					transition-property: left, right;
					-webkit-transition-duration: 0.3s;
					transition-duration: 0.3s;
					-webkit-transition-timing-function: ease-out;
					transition-timing-function: ease-out;
				}

				.hvr-underline-from-center:hover:before,
				.hvr-underline-from-center:focus:before,
				.hvr-underline-from-center:active:before {
					left: 0;
					right: 0;
				}

				.hvr-underline-from-two {
					display: inline-block;
					vertical-align: middle;
					-webkit-transform: translateZ(0);
					transform: translateZ(0);
					box-shadow: 0 0 1px rgba(0, 0, 0, 0);
					-webkit-backface-visibility: hidden;
					backface-visibility: hidden;
					-moz-osx-font-smoothing: grayscale;
					position: relative;
				}

				.hvr-underline-from-two:before {
					content: "";
					position: absolute;
					z-index: -1;
					left: 0;
					right: 0;
					bottom: -18px;
					background: #AB0101;
					height: 2px;
					//border-radius: 4px;
					-webkit-transition-property: left, right;
					transition-property: left, right;
					-webkit-transition-duration: 0.3s;
					transition-duration: 0.3s;
					-webkit-transition-timing-function: ease-out;
					transition-timing-function: ease-out;
				}

				/* Underline From Center */
			}
		}
	}
</style>
