import request from '@/util/request.js';
//查询企业页面数据
export function getContactUsList(params){
	request.header='json'
	return request.service.post('/apis/contactus/getContactUsList',params)
}
//查询专业领域信息列表
export function getProfessionalFieldList(params){
	request.header='json'
	return request.service.post('/apis/professionfield/getProfessionalFieldList',params)
}
//查询专业领域详情
export function getProfessionFieldDetail(params){
	request.header='json'
	return request.service.post('/apis/professionfield/getProfessionFieldDetail',params)
}
//新闻列表
export function newsList(params){
	request.header='json'
	return request.service.post('/apis/news/newsList',params)
}
//新闻详情
export function getNewsDetail(params){
	request.header='json'
	return request.service.post('/apis/news/getNewsDetail',params)
}
//状态分类列表
export function getCategoryList(params){
	request.header='json'
	return request.service.post('/apis/category/getCategoryList',params)
}
