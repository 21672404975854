import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);



const routes = [{ //首页
		path: "/",
		name: "home",
		component: () => import("../views/home/home.vue")
	},
	{ //首页-app
		path: "/homeApp",
		name: "homeApp",
		component: () => import("../views/home/home-app.vue")
	},
	{ //关于有容
		path: "/about-tolerant",
		name: "aboutTolerant",
		component: () => import("../views/about-tolerant/about-tolerant.vue")
	},
	{ //关于有容-app
		path: "/about-tolerant-app",
		name: "aboutTolerantApp",
		component: () => import("../views/about-tolerant/about-tolerant-app.vue")
	},
	{ //专业领域
		path: "/professional-field",
		name: "professionalField",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/professional-field/professional-field.vue")
	},
	{ //专业领域详情
		path: "/professional-field-details/:uuid",
		name: "professionalFieldDetails",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/professional-field/professional-field-details.vue")
	},
	{ //专业领域-app
		path: "/professional-field-app",
		name: "professionalFieldApp",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/professional-field/professional-field-app.vue")
	},
	{ //招贤纳士
		path: "/recruitment",
		name: "recruitment",
		component: () => import("../views/recruitment/recruitment.vue")
	},
	{ //招贤纳士-app
		path: "/recruitment-app",
		name: "recruitmentApp",
		component: () => import("../views/recruitment/recruitment-app.vue")
	},
	{ //联系我们
		path: "/contact-us",
		name: "contactUs",
		component: () => import("../views/contact-us/contact-us.vue")
	},
	{ //联系我们-app
		path: "/contact-us-app",
		name: "contactUsApp",
		component: () => import("../views/contact-us/contact-us-app.vue")
	},
	{ //法律知识
		path: "/news-center",
		name: "newsCenter",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/news-center/news-center.vue")
	},
	{ //法律知识详情
		path: "/news-details/:uuid",
		name: "newsDetails",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/news-center/news-details.vue")
	},
	{ //法律知识-app
		path: "/news-center-app",
		name: "newsCenterApp",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/news-center/news-center-app.vue")
	},
	{ //应收账款
		path: "/accounts-receivable",
		name: "accountsReceivable",
		component: () => import("../views/accounts-receivable/accounts-receivable.vue")
	},
	{ //应收账款-app
		path: "/accounts-receivable-app",
		name: "accountsReceivableApp",
		component: () => import("../views/accounts-receivable/accounts-receivable-app.vue")
	},
	{ //法律顾问
		path: "/legal-counsel",
		name: "legalCounsel",
		component: () => import("../views/legal-counsel/legal-counsel.vue")
	},
	{ //法律顾问-app
		path: "/legal-counsel-app",
		name: "legalCounselApp",
		component: () => import("../views/legal-counsel/legal-counsel-app.vue")
	},
	{ //刑事
		path: "/criminal",
		name: "criminal",
		component: () => import("../views/criminal/criminal.vue")
	},
	{ //刑事-app
		path: "/criminal-app",
		name: "criminalApp",
		component: () => import("../views/criminal/criminal-app.vue")
	},
	{ //民商事
		path: "/civil/:civilCategoryIndex/:uuid",
		name: "civil",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/civil/civil.vue")
	},
	{ //民商事-app
		path: "/civil-app",
		name: "civilApp",
		meta: {
			whiteDetail: true
		},
		component: () => import("../views/civil/civil-app.vue")
	},
	{ //网站地图
		path: "/sitemap",
		name: "sitemap",
		component: () => import("../views/sitemap/sitemap.vue")
	},
	{ //网站地图-app
		path: "/sitemap-app",
		name: "sitemapApp",
		component: () => import("../views/sitemap/sitemap-app.vue")
	},
]



const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	}
});


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
//路由拦截，app/pc 的路由跳转
router.beforeEach((to, from, next) => {
	if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
		if (to.name == 'home'){
			router.push({
				name: 'homeApp',
			});
		}
		if (to.name == 'aboutTolerant'){
			router.push({
				name: 'aboutTolerantApp',
			});
		}
		if (to.name == 'professionalField' || to.name == 'professionalFieldDetails'){
			router.push({
				name: 'professionalFieldApp',
			});
		}
		if (to.name == 'recruitment'){
			router.push({
				name: 'recruitmentApp',
			});
		}
		if (to.name == 'contactUs'){
			router.push({
				name: 'contactUsApp',
			});
		}
		if (to.name == 'newsCenter' || to.name == 'newsDetails'){
			router.push({
				name: 'newsCenterApp',
			});
		}
		if (to.name == 'accountsReceivable'){
			router.push({
				name: 'accountsReceivableApp',
			});
		}
		if (to.name == 'legalCounsel'){
			router.push({
				name: 'legalCounselApp',
			});
		}
		if (to.name == 'criminal'){
			router.push({
				name: 'criminalApp',
			});
		}
		if (to.name == 'civil'){
			router.push({
				name: 'civilApp',
			});
		}
		if (to.name == 'sitemap'){
			router.push({
				name: 'sitemapApp',
			});
		}
	}
	next();

});
export default router;
