var metaInfo = {
	home: { //首页
		title: '青岛律所 - 山东有容律师事务所 ｜综合性、一站式法律服务机构',
		meta: [{
				name: 'keywords',
				content: '青岛律师,青岛律师事务所,有容律所'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，专业领域涵盖：婚姻遗产、劳动纠纷等民商事诉讼与仲裁，刑事辩护、刑事法律风险防范等刑事法律服务、应收帐款代理回款及合同纠纷、公司法、债券债务等方面的法律顾问服务。'
			}
		]
	},
	accountsReceivable: { //应收账款
		title: '应收账款回款 - 青岛应收账款维权 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '应收账款回款,青岛应收账款维权'
			},
			{
				name: 'description',
				content: '针对不同的情况，制定不同的回款方案和非诉、诉讼策略，有容律所为客户解决回款难、回款慢、履约事实模糊、维权成本高、预算不足等问题，实现快速回款，为客户合法权益保驾护航。'
			}
		]
	},
	legalCounsel: { //法律顾问
		title: '青岛法律咨询 - 专业法律咨询律师事务所 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛法律顾问,青岛法律咨询律所,劳动纠纷法律咨询,合同纠纷法律咨询,公司法法律咨询,债权债务维权,知识产权法律咨询'
			},
			{
				name: 'description',
				content: '有容律所，针对个人和企业提供专业的法律咨询和法律风险防控服务，法律顾问服务产品涵盖：劳动纠纷、合同纠纷、公司法、债权债务及知识产权等多方面的内容。有容律所，提供专业的法律咨询服务，用丰富的法律知识维护您的合法权益。'
			}
		]
	},
	criminal: { //刑事
		title: '青岛刑事律师 - 刑事辩护 - 取保候审 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛刑事律师,刑事辩护律师,青岛取保候审,刑事律师事务所,刑事案件律师,刑事诉讼律师'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，专业受理青岛刑事案件，涉及律师会见、取保候审、刑事辩护、减刑假释等多方面法律事务，严格遵守统一的收费标准，提供严谨、具体、有针对性和可行性的刑事诉讼策略和刑事辩护方案。'
			}
		]
	},
	civil: { //民商事
		title: '合同纠纷 - 婚姻遗产 - 侵权纠纷 - 劳动纠纷 - 侵权 ｜ 有容律师',
		meta: [{
				name: 'keywords',
				content: '青岛民事律师,青岛民商事律师'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，拥有10年以上执业经验的专业民商事律师团队，注重隐私，提供舒适私密的民商事咨询环境，专注于合同纠纷、婚姻遗产、侵权纠纷、劳动纠纷及股权等民商事诉讼。'
			}
		]
	},
	contractualDispute: { //民商事-合同纠纷
		title: '青岛合同纠纷律师 - 企业合同律师 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛合同纠纷律师,企业合同法律咨询,合同纠纷律师'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，根据个人或企业需求，从合同起草、合同审查、合同履行及合同纠纷等多个方面为客户提供专业的法律服务，为您的合法权益保驾护航。'
			}
		]
	},
	maritalInheritance: { //民商事-婚姻遗产
		title: '青岛离婚律师 - 诉讼离婚 - 协议离婚 - 离婚子女 - 遗产继承 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛离婚律师,离婚律师,离婚诉讼,协议离婚,婚姻律师,遗产继承顺序'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，专业的婚姻家事法律服务律师事务所，帮您了解婚姻法律知识，针对是否离婚、财产分割、子女抚养等法律问题，提供专业的法律咨询以及协议离婚、诉讼离婚等多形式的法律服务。'
			}
		]
	},
	infringementDisputes: { //民商事-侵权纠纷
		title: '青岛侵权责任纠纷诉讼代理、非诉法律服务 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛侵权律师,侵权纠纷律师,侵权责任纠纷律师'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，对机动车交通事故责任纠纷、产品责任纠纷、医疗损害纠纷、建筑物和物件损害责任纠纷、饲养动物损害责任纠纷等侵权责任纠纷提供专业的诉讼代理法律服务及有需求的非诉法律服务，为您的合法权益保驾护航。'
			}
		]
	},
	laborDispute: { //民商事-劳动纠纷
		title: '劳动合同法律服务 - 青岛劳动纠纷律师服务 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛劳动纠纷律师,青岛劳动仲裁律师,劳动纠纷律师,劳动争议律师,劳动纠纷法律援助'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，根据个人或企业需求，提供劳动合同全流程法律服务以及对经济补偿金、工商认定处理、员工离职协商、劳动争议仲裁等企业劳动用工十大法律风险进行系统管控，为您的合法权益保驾护航。'
			}
		]
	},
	equity: { //民商事-股权
		title: '青岛股权方案设计 - 股权争议解决 - 股权融资 - 股权税务筹划 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛股权律师,股权律师咨询,股权争议解决,股权融资服务,股权税务筹划'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，专注股权方案实施，由专业律师深入企业，了解各方诉求，为企业打造系统的股权方案，有效帮助企业落地实施股权方案，解决股权争议和公司治理困局，确保实施效果。'
			}
		]
	},
	professionalField: { //专业领域
		title: '专业法律服务 - 青岛综合性律师事务所 | 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛法律服务,青岛法律咨询,青岛综合性律师事务所'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，专业领域涵盖：银行与金融、行政诉讼、劳动与雇佣、知识产权、刑事、公司综合类业务、环境与资源、海事海商、民商事诉讼与仲裁、房地产与建设工程、家事业务及私人财富管理、企业应收账款服务等。'
			}
		]
	},
	bankingFinance: { //专业领域-银行与金融
		title: '青岛银行与金融法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛银行与金融法律服务,青岛银行与金融法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，专业领域涵盖：银行与金融、行政诉讼、劳动与雇佣、知识产权、刑事、公司综合类业务、环境与资源、海事海商、民商事诉讼与仲裁、房地产与建设工程、家事业务及私人财富管理、企业应收账款服务等。'
			}
		]
	},
	administrativeLitigation: { //专业领域-行政诉讼
		title: '青岛行政诉讼法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛行政诉讼法律服务,青岛行政诉讼法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的行政诉讼法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	laborEmployment: { //专业领域-劳动与雇佣
		title: '青岛劳动与雇佣法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛劳动与雇佣法律服务,青岛劳动与雇佣法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的劳动与雇佣法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	intellectualProperty: { //专业领域-知识产权
		title: '青岛知识产权法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛知识产权法律服务,青岛知识产权法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的知识产权法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	professionalCriminal: { //专业领域-刑事
		title: '青岛刑事法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛刑事法律服务,青岛刑事法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的刑事法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	comprehensiveBusiness: { //专业领域-公司综合类业务
		title: '青岛公司综合类业务法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛公司综合类业务法律服务,青岛公司综合类业务法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的公司综合类业务法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	environmentResources: { //专业领域-环境与资源
		title: '青岛环境与资源法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛环境与资源法律服务,青岛环境与资源法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的环境与资源法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	maritimeMaritime: { //专业领域-海事海商
		title: '青岛海事海商法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛海事海商法律服务,青岛海事海商法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的海事海商法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	litigationArbitration: { //专业领域-民商事诉讼与仲裁
		title: '青岛民商事诉讼与仲裁法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛民商事诉讼与仲裁法律服务,青岛民商事诉讼与仲裁法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的民商事诉讼与仲裁法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	constructionProject: { //专业领域-房地产与建设工程
		title: '青岛房地产与建设工程法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛房地产与建设工程法律服务,青岛房地产与建设工程法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的房地产与建设工程法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	housework: { //专业领域-家事业务与私人财富管理
		title: '青岛家事业务与私人财富管理法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛家事业务与私人财富管理法律服务,青岛家事业务与私人财富管理法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的家事业务与私人财富管理法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	corporateAccounts: { //专业领域-企业应收账款服务
		title: '青岛企业应收账款服务法律服务 - 青岛法律咨询 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛企业应收账款法律服务,青岛企业应收账款法律咨询'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，提供专业的企业应收账款法律服务和法律咨询，价格透明、专业精细，致力于为客户提供全方位、高效、专业的法律服务。'
			}
		]
	},
	newsCenter: { //法律知识
		title: '法律知识 - 精选案例 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '法律知识'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，致力于为客户提供全方位、高效、专业的法律服务，业务领域涵盖：婚姻遗产、劳动纠纷等民商事诉讼与仲裁，刑事辩护、刑事法律风险防范等刑事法律服务、应收帐款代理回款及合同纠纷、公司法、债券债务等方面的法律顾问服务。'
			}
		]
	},
	newsDetails: { //新闻中心 — 详情
		title: 'xxxxx（新闻标题）- 法律知识 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '法律知识'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，致力于为客户提供全方位、高效、专业的法律服务，业务领域涵盖：婚姻遗产、劳动纠纷等民商事诉讼与仲裁，刑事辩护、刑事法律风险防范等刑事法律服务、应收帐款代理回款及合同纠纷、公司法、债券债务等方面的法律顾问服务。'
			}
		]
	},
	contactUs: { //联系我们
		title: '联系我们 - 山东有容律师事务所 ｜ 有容律所',
		meta: [{
				name: 'keywords',
				content: '青岛法律服务,青岛法律咨询,青岛综合性律师事务所'
			},
			{
				name: 'description',
				content: '山东有容律师事务所，是一家综合性、一站式的专业法律服务机构，专业领域涵盖：银行与金融、行政诉讼、劳动与雇佣、知识产权、刑事、公司综合类业务、环境与资源、海事海商、民商事诉讼与仲裁、房地产与建设工程、家事业务及私人财富管理、企业应收账款服务等。'
			}
		]
	},

};
if (window) window.$VueMetaInfo = metaInfo;
export default metaInfo;
