import axios from 'axios'
import store from '../store'
import walletutil from '../../static/js/walletutil.js'
import {
	Message
} from 'element-ui';
const request = {
	service: null
}
var tflag = false
var ignoreList = [
	'frontUserLogin',
	'openapi'
]
var ignoreFlag = true
request.service = axios.create({
	//	baseURL: 'http://192.168.2.3:9999/mock/10', 
	//timeout: 5000 // request timeout
	baseURL: '/webapi',
})
request.service.interceptors.request.use(config => {
	//debugger
	var dateTime = new Date().getTime()
	config.headers.key = walletutil.tripledesPass('gaeaweb@!#' + dateTime)
	if (store.state.loginInfo && localStorage.getItem(store.state.loginInfo.uuid)) {
		config.headers.Authorization = localStorage.getItem(store.state.loginInfo.uuid)
	}
	if (request.header == 'json') {
		config.headers['Content-Type'] = 'application/json;charset=UTF-8'
	}
	if (request.header == 'default') {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
	}
	if (request.header == 'multipart') {

		config.headers['Content-Type'] = 'multipart/form-data'
	}
	return new Promise(resolve => {
		resolve(config)
	})
})
request.service.interceptors.response.use(response => {
		// console.log(response)
		if (response.data.user) {
			store.state.loginInfo = response.data.user
		}

		if (response.headers.authorization) {
			localStorage.setItem(store.state.loginInfo.uuid, response.headers.authorization);
		}
		// if(response.data.msg=='1010'){
		// 	Message.error('你有未填写的信息，请填写后再试 ！')
		// }
		// if(response.config.url.includes('/userInfo/updateUserPassword') && response.data.msg=='2003'){
		// 	Message.error('原密码错误 ！')
		// }
		// if(response.data.msg=='12004'){
		// 	Message.error('职位邀请信息已经发送，请勿重复操作！')
		// }
		return new Promise(resolve => {
			resolve(response)
		})
	},
	error => {
		console.log(error.response)
		if (error.response) {
			if (store.state.loginInfo && store.state.loginInfo.uuid && error.response.status == 4002) {
				store.commit('modifyRequestStatus', 40001)
			}else if (error.response.status == 401) {
				if (store.state.loginInfo && store.state.loginInfo.uuid && error.response.data.code == 40003) {
					store.commit('modifyRequestStatus', 40003)
					return
				}
				if(store.state.loginInfo && store.state.loginInfo.uuid){
					store.commit('modifyRequestStatus', 40001)
					return
				}
				// 登录过期
				if (store.state.loginInfo) {
					localStorage.removeItem(store.state.loginInfo.uuid);
				}
				store.state.loginInfo = {}
				sessionStorage.removeItem('requestStatus')
				store.commit('modifyLoginInfo', {})
				sessionStorage.removeItem('loginInfo')
				store.commit('modifyRequestStatus', 401)
				// if(!tflag){
				// 	Message.error('请先登录')
				// 	tflag = true
				// }
				// setTimeout(()=>{
				// 	window.location.href = '/';
				// 	tflag = false
				// },1000)
			}
			if (error.response.status == 2003) {
				// 登录过期，跳到登入页
				if (store.state.loginInfo) {
					localStorage.removeItem(store.state.loginInfo.uuid);
				}
				store.state.loginInfo = {}
				sessionStorage.removeItem('requestStatus')
				store.commit('modifyLoginInfo', {})
				sessionStorage.removeItem('loginInfo')
				window.location.href = '/#/login';

			}
		}
	})
export default request
