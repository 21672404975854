import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		cryptoJSKey:'d72f37ba125ccb702b96db7943f8da49',
		cryptoJSIv:'64adf133102f8b91241565==',
		loginInfo:JSON.parse(sessionStorage.getItem('loginInfo')) || null, //登录后信息
		companyInfo:JSON.parse(sessionStorage.getItem('companyInfo')) || null, //公司信息
		categoryInfo:JSON.parse(sessionStorage.getItem('categoryInfo')) || null, //分类信息
		headFieldList:JSON.parse(sessionStorage.getItem('headFieldList')) || null, //专业领域分类信息
		requestStatus:sessionStorage.getItem('requestStatus')||0 ,//请求状态 根据请求状态到登录页弹窗
		uploadUrl:'webapi/',//上传文件/图片 开发api,生产webapi
		refAudioPlay:null,
		scroll:0,//滑动距离顶部的高度
	},
	mutations: {
		modifyHeadFieldList(state,n){
			state.headFieldList = n
			sessionStorage.setItem('headFieldList',JSON.stringify(n))
		},
		modifyCategoryInfo(state,n){
			state.categoryInfo = n
			sessionStorage.setItem('categoryInfo',JSON.stringify(n))
		},
		modifyCompanyInfo(state,n){
			state.companyInfo = n
			sessionStorage.setItem('companyInfo',JSON.stringify(n))
		},
		modifyLoginInfo(state,n){
			state.loginInfo = n
			sessionStorage.setItem('loginInfo',JSON.stringify(n))
		},
		modifyRequestStatus(state,n){
			state.requestStatus = n
			sessionStorage.setItem('requestStatus',n)
		},
	},
	actions: {},
	getters: {
		getloginInfo(state) {
			return state.loginInfo
		}
	},
});
