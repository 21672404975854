<template>
	<!-- start 顶部菜单 -->
	<div class="header-box">
		<div class="navigation-box">
			<img class="logo-image" @click="toggleNavigation('/homeApp')" src="../../../static/image/home/logo-app.png" />
			<div v-if="showNavigation" class="classification-box">
				<div class="classification-content">
					<img class="close-navigation" @click.stop="showNavigation = false"
						src="../../../static/image/home-app/close-navigation.png" />
					<div class="classification-line"></div>
					<div class="classification-text-box">
						<div class=" classification-text" @click="toggleNavigation('/homeApp')"
							:style="{background:$route.path == '/homeApp'&& !showMoreNew?'rgba(245, 248, 250, 0.4)':'rgba(245, 248, 250, 0)'}">
							关于有容</div>
						<div class="classification-line"></div>
						<div v-for="(item,index) in classificationList.slice(0,4)">
							<div class=" classification-text" @click="toggleNavigation(pathUrl[index].url)"
								:style="{background:$route.path == pathUrl[index].url&& !showMoreNew ?'rgba(245, 248, 250, 0.4)':'rgba(245, 248, 250, 0)'}">
								{{item.categoryName}}</div>
							<div class="classification-line"></div>
						</div>
						<div class=" classification-text" @click="toggleNavigation('/professional-field-app')"
							:style="{background:$route.path == '/professional-field-app'&& !showMoreNew ?'rgba(245, 248, 250, 0.4)':'rgba(245, 248, 250, 0)'}">
							专业领域</div>
						<div class="classification-line"></div>
						<div class="classification-text" @click="toggleNavigation('/news-center-app')"
							:style="{background:$route.path == '/news-center-app' ||showMoreNew ?'rgba(245, 248, 250, 0.4)':'rgba(245, 248, 250, 0)'}">
							法律知识
						</div>
						<div class="classification-line"></div>
						<div class=" classification-text" @click="toggleNavigation('/contact-us-app')"
							:style="{background:$route.path == '/contact-us-app' && !showMoreNew ?'rgba(245, 248, 250, 0.4)':'rgba(245, 248, 250, 0)'}">
							联系我们</div>
						<div style="width: 100%; height: 0.24rem;"></div>
					</div>
				</div>
			</div>
			<img @click.stop="showNavigation = true" v-show='!showNavigation' src="../../../static/image/home-app/navigation.png" class="menu-img"/>
		</div>
	</div>
	<!-- end 顶部菜单 -->
</template>

<script>
	import {
		getContactUsList,
		getCategoryList
	} from '../../apis/openapi.js';
	export default {
		data() {
			return {
				showNavigation: false,
				showMoreNew: false,
				categoryUuid: -1,
				newsHeight:'0rem',
				classificationList:[
					{
						name:'最新业绩'
					},
					{
						name:'有容动态'
					},
					{
						name:'经典案例'
					},
					{
						name:'法律研究'
					},
				],
				pathUrl:[
					{
						url:'/accounts-receivable-app'
					},
					{
						url:'/legal-counsel-app'
					},
					{
						url:'/criminal-app'
					},
					{
						url:'/civil-app'
					},
				],
				enterpriseInfo: {}, //企业信息

			}
		},
		watch:{
			'$route' (to, from) {
				this.$nextTick(()=>{
					this.initMenuStatus()
				})
			}
		},
		mounted() {
			this.$nextTick(()=>{
				this.initMenuStatus()
			})
			if (this.$store.state.categoryInfo) {
				this.classificationList = this.$store.state.categoryInfo
				this.newsHeight = this.classificationList.length *0.8
			}else{
				this.getCategoryList()
			}
			if(this.$store.state.companyInfo){
				this.enterpriseInfo = this.$store.state.companyInfo
			}else{
				this.getContactUsList()
			}
		},
		methods: {
			initMenuStatus(){
				let meta = this.$route.meta
				let query = JSON.stringify(this.$route.query)
				let headMenu = document.getElementsByClassName('header-box')[0]
				let logoImage = document.getElementsByClassName('logo-image')[0]
				let menuImg = document.getElementsByClassName('menu-img')[0]
				if(meta.whiteDetail && query!='{}'){
					headMenu.classList.add('white-header')
					logoImage.src = require('../../../static/image/home/logo-black.png')
					menuImg.src = require('../../../static/image/home-app/red-menu.png')
				} else{
					headMenu.classList.remove('white-header')
					logoImage.src = require('../../../static/image/home/logo-app.png')
					menuImg.src = require('../../../static/image/home-app/navigation.png')
				}
			},
			//查询新闻分类
			getCategoryList() {
				let params = {
					page: 1,
					rows: 9999,
					categoryName: '',
					parentUuid: '0',
					categoryState: ''
				}
				getCategoryList(params).then(res => {
					if (res.data.success == 'true') {
						this.classificationList = res.data.data.data
						this.newsHeight = this.classificationList.length *0.8
					}
				})
			},
			//查询企业信息
			getContactUsList() {
				getContactUsList({}).then(res => {
					if (res.data.success == 'true') {
						this.enterpriseInfo = res.data.data
					}
				});
			},
			moreNews(index) {
				this.showMoreNew = !this.showMoreNew
			},
			//跳转分类（路由）
			toggleNavigation(url, categoryUuid) {
				this.$router.push({
					path: url,
					query: {
						categoryUuid: categoryUuid
					}
				});
				this.showNavigation = false
				//this.showMoreNew = false
				//this.$router.go(0)
			},

		}
	}
</script>
<style lang="scss" scoped>
	.header-box {
		width: 100%;
		height: 1rem;
		background: rgba(51, 51, 51, 0);
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		z-index: 999;

		.navigation-box {
			width: 100%;
			padding: 0.22rem 0.48rem 0.1rem 0.48rem;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			top: 0;
			background: rgba(0, 0, 0, 0.2);

			.logo-image {
				width: 1.86rem;
				height: 0.78rem;
				opacity: 1;
				box-sizing: border-box;

				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0.36rem;
			}

			img {
				width: 0.64rem;
				height: 0.46rem;
			}

			.classification-box {
				width: 3.44rem;
				//height: 6.62rem;
				background: rgba(51, 51, 51, 0.6);
				opacity: 1;
				position: absolute;
				top: 0;
				right: 0;

				.classification-content {
					width: 100%;
					height: 100%;
					padding-top: 1.2rem;
					box-sizing: border-box;
					position: relative;

					.close-navigation {
						width: 0.4rem;
						height: 0.4rem;
						position: absolute;
						top: 0.4rem;
						right: 0.48rem;
					}

					.classification-line {
						width: 2rem;
						border-top: 0.02rem solid #999999;
						opacity: 1;
						margin: 0 auto;
					}

					.classification-text-box {
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 0.26rem;

						.classification-text {
							width: 3.44rem;
							height: 0.98rem;
							background: rgba(245, 248, 250, 0);
							font-size: 0.36rem;
							font-family: PingFangSC-Medium;
							line-height: 0.98rem;
							color: #FFFFFF;
							opacity: 1;
							text-align: center;
							position: relative;

							.more {
								width: 0.12rem;
								height: 0.22rem;
								position: absolute;
								top: 0.38rem;
								right: 0.6rem;
								transition: all 0.4s;

							}
						}

						.classification-small-box {
							height: 100%;
							overflow: hidden;
							transition: all 0.4s;

							.classification-small {
								width: 3.44rem;
								height: 0.8rem;
								background: rgba(245, 248, 250, 0);
								font-size: 0.28rem;
								font-family: PingFangSC-Regular;
								font-weight: 400;
								line-height: 0.8rem;
								color: #CCCCCC;
								opacity: 1;
								text-align: center;
							}

							.classification-small:hover {
								background: rgba(245, 248, 250, 0.4);
							}

							.small-line {
								width: 1.6rem;
								border-top: 0.02rem solid #999999;
								opacity: 1;
								margin: 0 auto;
							}
						}

						.classification-line {
							width: 2rem;
							border-top: 0.02rem solid #999999;
							opacity: 1;
							margin: 0 auto;
						}
					}
				}
			}

		}
	}
	.white-header{
		.navigation-box{
			background: #FFFFFF;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
		}
	}
</style>
