export default {
	/* 
	 *@msgNum 错误码
	 *@defaultMsg 默认失败提示
	 */
	getMsg(msgNum='', defaultMsg = '失败:') {
		let errMsg = ''
		let msgObj = {
			1001: '请输入你的电子邮箱！',
			1002: '请输入你的密码 ！',
			1003: '验证码错误！',
			1006: '邮箱已被注册！',
			1010: '参数错误！',
			1011: '操作失败！',
			1028: '邀请码错误！',
			1029: '请绑定设备！',
			1030: '当前设备已绑定。请更换设备！',
			1031: '注册帐户和设备不匹配！',
			1034: '地址不能为空！',
			1035: '地址已被绑定！',
			1037: '用户名密码错误！',
			1038: '评论内容超长！',
			1501: '验证失败！',
			2003: '用户不存在！',
			2009: '您已评论！',
			2024: '验证码已过期！',
			2025: '验证码已超时！',
			2026: '请忽重复点击！',
			2040: '停止注册！',
			2043: '课程为空！',
			2057: '课程不允许加入！',
			7001: '邀请码错误！',
			11001: '用户帐号已经绑定！',
			11002: '邀请人不存在！',
			11003: '邀请码格式错误！',
			11004: '无法使用自己的邀请码！',
			20001: '操作频繁，请稍后再试！',
			20002: '含有非法字符！',
			21001: '邀请码已绑定！',
			21002: '华校审核通过！',
			21003: '华校审核拒绝！',
			21007: '请绑定华校！',
			30001: '发现内容为空！',
			30002: '发现内容过长！',
			30003: '发现话题不存在！',
			30004: '发现转发UUID为null！',
			30005: '发现不存在！',
			30006: '发现评论不存在！',
			30009: '回复不存在！',
			70001: '订单不属于你',
			70002: '订单已支付',
			70003: '订单超时',
			70004: '选择的学生不合法 (不存在，不属于你的组织，已被激活，等待订单确认)',
			70005: '账号激活配置丢失，请联系管理员',
			70006: '退款金额错误',
			70007: '退款失败',
			70008: '订单未完成',
			71001: '卡片已绑定',
			71002: '绑卡订单创建失败',
			71003: '绑卡失败',
			71004: '卡片不存在',
			71005: '卡片已绑定',
			71006: '卡片绑定中',
			71007: '卡片不属于你',
			72001: '钱包保存失败',
			72002: '钱包已存在，无需重复操作',
			72003: '用户未开通过钱包',
			72004: '学习币余额不足',
			73001: '您的个人信息不完善（手机号）',
			73002: '支付服务器错误',
			74001: '购买失败',
			74002: '下单失败',
			74003: '课程订单已存在，请勿重复下单',
			11003: '邀请码填写错误',
			11004: '邀请码不能为自己邀请码',
			90001: '您已申请过协会，请等待申请结果',
			90002: '协会状态错误',
			90003: '您已申请过协会，请等待申请结果'
		}
		//是否是汉字
		if (isNaN(msgNum)) {
			return msgNum
		}
		errMsg = msgObj[parseInt(msgNum)] ? msgObj[parseInt(msgNum)] : defaultMsg + msgNum
		return errMsg
	}
}
