<template>
	<!-- start 底部导航 -->
	<div class="bottom-box">
		<div class="navigation-top-box">
			<div class="navigation-top">
				<div class="navigation-box">
					<div class="navigation-text"  @click="toggleNavigation('/homeApp')">关于有容</div><!-- :style="{color:$route.path == '/homeApp'?'#FFFFFF':'#999999'}" -->
					<div class="navigation-text"  @click="toggleNavigation(pathUrl[index].url)" v-for="(item,index) in categoryList.slice(0,4)">{{item.categoryName}}</div><!-- :style="{color:$route.path == pathUrl[index].url?'#FFFFFF':'#999999'}" -->
					<div class="navigation-text"  @click="toggleNavigation('/professional-field-app')">专业领域</div><!-- :style="{color:$route.path == '/professional-field-app'?'#FFFFFF':'#999999'}" -->
					<div class="navigation-text"  @click="toggleNavigation('/news-center-app')">法律知识</div><!-- :style="{color:$route.path == '/news-center-app'?'#FFFFFF':'#999999'}" -->
					<div class="navigation-text"  @click="toggleNavigation('/contact-us-app')">联系我们</div><!-- :style="{color:$route.path == '/contact-us-app'?'#FFFFFF':'#999999'}" -->
					<div class="navigation-text"  @click="toggleNavigation('/sitemap-app')">网站地图</div><!-- :style="{color:$route.path == '/sitemap-app'?'#FFFFFF':'#999999'}" -->
				</div>
				<div class="contact-information">
					<div class="service-hotline-box" style="margin-top: 0;">
						<div class="service-hotline-title">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-phone-app.png" />
							<span>服务热线</span>
						</div>
						<div class="service-hotline-text">{{enterpriseInfo.phone}}</div>
					</div>
					<div class="service-hotline-box">
						<div class="service-hotline-title">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-weChat-app.png" />
							<span>微信号</span>
						</div>
						<div class="service-hotline-text">{{enterpriseInfo.phone}}</div>
					</div>
					<div class="service-hotline-box">
						<div class="service-hotline-title">
							<img class="service-hotline-img" src="../../../static/image/footer/foot-email-app.png" />
							<span>邮箱</span>
						</div>
						<div class="service-hotline-text">{{enterpriseInfo.email}}</div>
					</div>
				</div>
			</div>
			<div class="applets-box">
				<div class="applets">
					<div class="code-box"><img class="code" :src="enterpriseInfo.weChatCodeUrl" /></div>
					<div class="applets-text">有容二维码</div>
				</div>
			</div>
		</div>
		<div class="navigation-bottom">
			<div class="navigation-bottom-text">{{enterpriseInfo.address}}</div>
			<div class="navigation-bottom-text">©2022 有容律师事务所 备案号：***********</div>
			<div class="navigation-bottom-text">京公网安备 **************</div>
		</div>
	</div>
	<!-- end 底部导航 -->
</template>

<script>
	import {
		getContactUsList,
		getCategoryList
	} from '../../apis/openapi.js';
	export default {
		data() {
			return {
				enterpriseInfo: {}, //企业信息
				categoryList:[],//分类信息
				pathUrl:[
					{
						url:'/accounts-receivable-app'
					},
					{
						url:'/legal-counsel-app'
					},
					{
						url:'/criminal-app'
					},
					{
						url:'/civil-app'
					},
				]
				
			}
		},
		created() {
	
		},
		mounted() {
			if(this.$store.state.categoryInfo){
				this.categoryList = this.$store.state.categoryInfo
			}else{
				this.getCategoryList()
			}
			if(this.$store.state.companyInfo){
				this.enterpriseInfo = this.$store.state.companyInfo
			}else{
				this.getContactUsList()
			}
		},
		methods: {
			//查询分类
			getCategoryList() {
				let params = {
					page: 1,
					rows: 9999,
					categoryName: '',
					parentUuid: '0',
					categoryState: ''
				}
				getCategoryList(params).then(res => {
					if (res.data.success == 'true') {
						this.categoryList = res.data.data.data
						var categoryInfo = res.data.data.data
						this.$store.commit('modifyCategoryInfo', categoryInfo);
						localStorage.setItem('categoryInfo',JSON.stringify(categoryInfo))
						console.log(this.categoryList)
					}
				})
			},
			//查询企业信息
			getContactUsList() {
				getContactUsList({}).then(res => {
					if (res.data.success == 'true') {
						this.enterpriseInfo = res.data.data
					}
				});
			},
			//跳转分类（路由）
			toggleNavigation(url) {
				this.$router.push({
					path: url,
					query: {
					}
				});
			
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.bottom-box{
		width: 100%;
		opacity: 1;
		.navigation-top-box{
			height: 10.46rem;
			background: #2A2C37;
			.navigation-top{
				display: flex;
				//align-items: center;
				justify-content: space-between;
				padding: 0.48rem 0.88rem 0.8rem 0.88rem;
				box-sizing: border-box;
				.navigation-box{
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					.navigation-text{
						height: 0.28rem;
						font-size: 0.28rem;
						font-family: SourceHanSansCN-Regular;
						font-weight: 400;
						line-height: 0.28rem;
						color: #999999;
						letter-spacing: 0.28px;
						opacity: 1;
						margin-top: 0.28rem;
						white-space: nowrap;
					}
					// .navigation-text:hover{
					// 	color: #FFFFFF;
					// }
				}
				.contact-information{
					.service-hotline-box{
						display: flex;
						flex-direction: column;
						align-items: center;
						margin-top: 0.6rem;
						.service-hotline-title{
							display: flex;
							align-items: flex-end;
							img{
								width: 0.8rem;
								height: 0.8rem;
							}
							span{
								height: 0.4rem;
								font-size: 0.28rem;
								font-family: PingFangSC-Regular;
								font-weight: 400;
								line-height: 0.4rem;
								color: #999999;
								letter-spacing: 0.28px;
								opacity: 1;
								margin-left: 0.28rem;
							}
						}
						.service-hotline-text{
							height: 0.4rem;
							font-size: 0.28rem;
							font-family: PingFangSC-Regular;
							font-weight: 400;
							line-height: 0.4rem;
							color: #999999;
							letter-spacing: 0.28px;
							opacity: 1;
							margin-top: 0.28rem;
						}
					}
				}
			}
			.applets-box{
				margin: 0 auto;
				.applets{
					display: flex;
					flex-direction: column;
					align-items: center;
					.code-box{
						width: 2.2rem;
						height: 2.2rem;
						display: flex;
						align-items: center;
						justify-content: center;
						.code{
							width: 1.96rem;
							height: 1.96rem;
						}
					}
					.applets-text{
						//width: 1.44rem;
						height: 0.28rem;
						font-size: 0.28rem;
						font-family: SourceHanSansCN-Regular;
						font-weight: 400;
						line-height: 0.28rem;
						color: #999999;
						letter-spacing: 0.28px;
						opacity: 1;
						margin-top: 0.2rem;
					}
				}
			}
		}
		.navigation-bottom{
			width: 100%;
			height: 2.8rem;
			background: #171C28;
			opacity: 1;
			padding: 0.32rem 0;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			.navigation-bottom-text{
				width: 5.7rem;
				font-size: 0.28rem;
				font-family: PingFangSC-Regular;
				font-weight: 400;
				line-height: 0.4rem;
				color: #999999;
				text-align: center;
				letter-spacing: 0.28px;
				opacity: 1;
			}
		}
	}

</style>