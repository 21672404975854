import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import ElementUI from 'element-ui';
import errorCodeUtil from '../static/js/errorCode/errorCodeUtil';
import 'element-ui/lib/theme-chalk/index.css';
import $ from 'jquery';
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import wcView from 'wc-view';
import 'wc-view/style.css';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import BaiduMap from 'vue-baidu-map'
import VueJsonp from 'vue-jsonp'
import VideoPlayer from 'vue-video-player'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
import MetaInfo from 'vue-meta-info'
import VueMetaInfo from '@/vue-meta-info.js';

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(MetaInfo)
Vue.use(VueFullPage);
Vue.use(VideoPlayer)
Vue.use(VueJsonp)
Vue.use(BaiduMap,{ak:'RUtYzGFiwYpyQuxyjmrwaXydLELioNt4'})
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.use(wcView);
Vue.use(ElementUI);
Vue.use(vueSwiper)
Vue.config.productionTip = false;
//错误码处理 $errorCodeUtil.getMsg(错误码)
Vue.prototype.$errorCodeUtil = errorCodeUtil;
Vue.prototype.$VueMetaInfo = VueMetaInfo;

router.afterEach((to, from)=>{
	window.scrollTo(0, 0);
	document.documentElement.scrollTop = document.body.scrollTop = 0;
})
console.error = function(){}
console.log = function(){}
console.info = function(){}
new Vue({
	el:'#app',
    router,
    store,
    render: h => h(App),
	mounted () {
	  document.dispatchEvent(new Event('render-event')) // 需要加上
	},
    created(){
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题
		
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
           setRem();
           window.addEventListener('resize', setRem);//浏览器窗口大小改变时调用rem换算方法
          }
          // else{
          //    setRemPC()  
          //    window.addEventListener('resize', setRemPc);//浏览器窗口大小改变时调用rem换算方法
          // }
	},
}).$mount('#app');
   
 // 手机端
    function setRem() {
      var whdef = 100 / 750; // 表示750的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
      var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    }
   //pc端
    function setRemPC() {
      var whdef = 100 / 1920; // 表示1920的设计图,使用100PX的默认值
      var bodyWidth = document.body.clientWidth; // 当前窗口的宽度
      var rem = bodyWidth * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
    }